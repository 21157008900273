
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";
import Main from "./components/Main";

// imported fonts
import "./fonts/Sailec Bold.ttf";

function App() {
  return (
  <Main/>
  );
}



export default App;
