import React from 'react'
import { Link } from 'react-router-dom'

function ContactUs() {
    return (
        <div className="fixed text-[#0c1219] sailec-medium bottom-14 contact-us right-5 md:right-8 z-5 bg-[#32cd32] rounded-[25px]">
            <Link to="https://wa.me/2347049668561" className='flex items-center gap-2 py-2'>
                <span className="icon">
                    <svg
                        width="36"
                        height="36"
                        viewBox="0 0 36 36"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <circle cx="18" cy="18" r="18" fill="white" />
                        <g clip-path="url(#clip0_740_2141)">
                            <path
                                d="M23.601 12.3261C22.8676 11.5856 21.9941 10.9985 21.0314 10.5991C20.0688 10.1998 19.0362 9.99614 17.994 10.0001C13.627 10.0001 10.068 13.5581 10.064 17.9261C10.064 19.3251 10.43 20.6861 11.121 21.8911L10 26.0001L14.204 24.8981C15.3665 25.5323 16.6698 25.8641 17.994 25.8631H17.998C22.366 25.8631 25.924 22.3051 25.928 17.9331C25.9289 16.8911 25.7237 15.8593 25.3241 14.897C24.9246 13.9347 24.3396 13.061 23.601 12.3261ZM17.994 24.5211C16.8132 24.5201 15.6542 24.2024 14.638 23.6011L14.398 23.4571L11.904 24.1111L12.57 21.6781L12.414 21.4271C11.7538 20.3774 11.4046 19.1621 11.407 17.9221C11.407 14.2961 14.364 11.3381 17.998 11.3381C18.8637 11.3365 19.7212 11.5063 20.521 11.8377C21.3208 12.1692 22.0471 12.6556 22.658 13.2691C23.2709 13.8802 23.7568 14.6066 24.0877 15.4064C24.4186 16.2062 24.5879 17.0635 24.586 17.9291C24.582 21.5681 21.625 24.5211 17.994 24.5211ZM21.609 19.5871C21.412 19.4881 20.439 19.0091 20.256 18.9411C20.074 18.8761 19.941 18.8421 19.811 19.0401C19.678 19.2371 19.298 19.6861 19.184 19.8151C19.07 19.9481 18.952 19.9631 18.754 19.8651C18.557 19.7651 17.918 19.5571 17.162 18.8801C16.572 18.3551 16.177 17.7051 16.059 17.5081C15.945 17.3101 16.048 17.2041 16.147 17.1051C16.234 17.0171 16.344 16.8731 16.443 16.7591C16.543 16.6451 16.576 16.5611 16.641 16.4291C16.706 16.2951 16.675 16.1811 16.626 16.0821C16.576 15.9831 16.181 15.0061 16.014 14.6121C15.854 14.2231 15.691 14.2771 15.569 14.2721C15.455 14.2651 15.322 14.2651 15.189 14.2651C15.0886 14.2676 14.9898 14.2909 14.8987 14.3334C14.8077 14.3759 14.7264 14.4367 14.66 14.5121C14.478 14.7101 13.969 15.1891 13.969 16.1661C13.969 17.1431 14.679 18.0821 14.779 18.2151C14.877 18.3481 16.173 20.3471 18.162 21.2071C18.632 21.4121 19.002 21.5331 19.291 21.6251C19.766 21.7771 20.195 21.7541 20.537 21.7051C20.917 21.6471 21.708 21.2251 21.875 20.7621C22.039 20.2981 22.039 19.9021 21.989 19.8191C21.94 19.7351 21.807 19.6861 21.609 19.5871Z"
                                fill="#00AC57"
                            />
                        </g>
                        <defs>
                            <clipPath id="clip0_740_2141">
                                <rect
                                    width="16"
                                    height="16"
                                    fill="white"
                                    transform="translate(10 10)"
                                />
                            </clipPath>
                        </defs>
                    </svg>
                </span>
                Whatsapp us!
            </Link>
        </div>
    )
}

export default ContactUs
